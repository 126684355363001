import { BlogContents, blogImages } from '../rsrc';
import { blogSpec } from './home';

interface blogPageSpec {
  title: string;
  description: string;
  blogs: blogAndTabSpec;
}

export interface blogAndTabSpec {
  blogs: blogSpec[];
  tabs: string[];
}

export const blogData: blogPageSpec = {
  title: `Shweta Labs Blogs`,
  description: `Our blogs from our founders, analysts and experts analyze the latest developments in cyber-security, providing a variety of perspectives on this intriguing, exciting and challenging world. There are multiple lenses to see threats through and a single-minded aim – how to eliminate them.`,
  blogs: {
    tabs: [
      'Latest Post',
      'Category 1',
      'Category 2',
      'Category 3',
      'Category 4',
      'Category 5',
      'Category 6',
    ],
    blogs: [
      {
        image: blogImages.featuredBlog,
        date: '12 March, 2024',
        length: '3 min',
        title:
          'sanchit Alias et commodi repellat. Nostrum autem ut molestias accusamus iure.',
        buttonText: 'Read Full Article',
        data: BlogContents.blogContent1,
        url: '/blog/featured',
        categories: ['featured'],
      },
      {
        image: blogImages.blog3,
        date: '12 March, 2024',
        length: '3 min',
        title:
          'sanchit Alias et commodi repellat. Nostrum autem ut molestias accusamus iure.',
        buttonText: 'Read Full Article',
        data: BlogContents.blogContent2,
        url: '/blog/top1',
        categories: ['featured'],
      },
      {
        image: blogImages.blog1,
        date: '12 March, 2024',
        length: '3 min',
        title:
          'sanchit Alias et commodi repellat. Nostrum autem ut molestias accusamus iure.',
        buttonText: 'Read Full Article',
        data: BlogContents.blogContent3,
        url: '/blog/top2',
        categories: ['featured'],
      },
      {
        image: blogImages.blog1,
        date: '12 March, 2024',
        length: '3 min',
        title:
          'sanchit Alias et commodi repellat. Nostrum autem ut molestias accusamus iure.',
        buttonText: 'Read Full Article',
        data: BlogContents.blogContent4,
        categories: ['Latest Post', 'Category 3'],
      },
      {
        image: blogImages.blog2,
        date: '12 March, 2024',
        length: '3 min',
        title:
          'naveen Alias et commodi repellat. Nostrum autem ut molestias accusamus iure.',
        buttonText: 'Read Full Article',
        data: BlogContents.blogContent5,
        categories: ['Latest Post', 'Category 2'],
      },
      {
        image: blogImages.blog3,
        date: '12 March, 2024',
        length: '3 min',
        title:
          'sanchit Alias et commodi repellat. Nostrum autem ut molestias accusamus iure.',
        buttonText: 'Read Full Article',
        data: BlogContents.blogContent6,
        categories: ['Latest Post', 'Category 1'],
      },
      {
        image: blogImages.blog1,
        date: '12 March, 2024',
        length: '3 min',
        title:
          'sanchit Alias et commodi repellat. Nostrum autem ut molestias accusamus iure.',
        buttonText: 'Read Full Article',
        data: BlogContents.blogContent7,
        categories: ['Latest Post', 'Category 6'],
      },
      {
        image: blogImages.blog2,
        date: '12 March, 2024',
        length: '3 min',
        title:
          'sanchit Alias et commodi repellat. Nostrum autem ut molestias accusamus iure.',
        buttonText: 'Read Full Article',
        data: BlogContents.blogContent8,
        categories: ['Latest Post', 'Category 5'],
      },
      {
        image: blogImages.blog3,
        date: '12 March, 2024',
        length: '3 min',
        title:
          'sanchit Alias et commodi repellat. Nostrum autem ut molestias accusamus iure.',
        buttonText: 'Read Full Article',
        data: BlogContents.blogContent9,
        categories: ['Latest Post', 'Category 4'],
      },
      {
        image: blogImages.blog1,
        date: '12 March, 2024',
        length: '3 min',
        title:
          'sanchit Alias et commodi repellat. Nostrum autem ut molestias accusamus iure.',
        buttonText: 'Read Full Article',
        data: BlogContents.blogContent10,
        categories: ['Latest Post', 'Category 3'],
      },
      {
        image: blogImages.blog2,
        date: '12 March, 2024',
        length: '3 min',
        title:
          'sanchit Alias et commodi repellat. Nostrum autem ut molestias accusamus iure.',
        buttonText: 'Read Full Article',
        data: BlogContents.blogContent11,
        categories: ['Latest Post', 'Category 2'],
      },
    ],
  },
};
