import { blogSpec } from '../../data/home';
import { BlogTile } from '../blogTile/blogTile';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { blogAndTabSpec } from '../../data/blog';
import { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { isDesktop, parseHTML } from '../../utils';

interface blogSearchProp {
  blogSearch: blogAndTabSpec;
}

export function BlogSearch({ blogSearch }: blogSearchProp) {
  let [chosenTab, setChosenTab] = useState(0);
  let [shownBlogs, setShownBlogs] = useState<blogSpec[] | null>(
    blogSearch.blogs.slice(3),
  );
  let categoryToBlogs: { [category: string]: blogSpec[] } = {};
  let fuse = new Fuse(blogSearch.blogs, { keys: ['title'] });

  // useEffect(() => {
  blogSearch.tabs.map(cat => (categoryToBlogs[cat] = []));
  categoryToBlogs['featured'] = [];
  blogSearch.blogs.map((blog, id) => {
    blog.id = id;
    fetch(blog.data)
      .then(res => res.text())
      .then(data => {
        let temp;
        [blog.title, temp] = parseHTML(data);
      });
    blog.categories!.map(cat => {
      categoryToBlogs[cat].push(blog);
    });
  });
  // }, [blogSearch])
  //
  const TabsBar = (
    <Box sx={{ overflow: 'hidden', position: 'relative', fontSize: 'inherit' }}>
      <Tabs
        textColor='inherit'
        value={chosenTab}
        onChange={(_event: any, newValue: number) => {
          setChosenTab(newValue);
          setShownBlogs(categoryToBlogs[blogSearch.tabs[newValue]]);
        }}
        variant='scrollable'
        scrollButtons='auto'
        allowScrollButtonsMobile
        // went crazy learning this much css
        sx={{
          '& .MuiTab-root': {
            borderBottom: '3px solid rgb(50, 50, 50)',
            fontSize: 'inherit',
          },
          '& .MuiTabs-scrollButtons': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: 8,
            opacity: 1,
          },
          '& .MuiTabs-scrollButtons:first-of-type': {
            left: 0,
          },
          '& .MuiTabs-scrollButtons:last-of-type': {
            right: 0,
          },
          '& .MuiTabs-scrollButtons:last-of-type::before, & .MuiTabs-scrollButtons:first-of-type::after':
            {
              content: '""',
              position: 'absolute',
              top: 0,
              bottom: 0,
              width: '200%',
              pointerEvents: 'none',
              zIndex: 2,
            },
          '& .MuiTabs-scrollButtons:last-of-type::before': {
            right: 0,
            background: `linear-gradient(to left, rgba(0,0,0,255) 60%,rgba(0,0,0,0) 100%);`,
          },
          '& .MuiTabs-scrollButtons:first-of-type::after': {
            left: 0,
            background: `linear-gradient(to right, rgba(0,0,0,255) 60%,rgba(0,0,0,0) 100%);`,
          },
          '.MuiSvgIcon-root': {
            zIndex: 5,
          },
          [`& .${tabsClasses.indicator}`]: {
            backgroundColor: '#ffffff',
          },
        }}
      >
        {blogSearch.tabs.map(id => (
          <Tab label={id} />
        ))}
      </Tabs>
    </Box>
  );

  const SearchBox = (
    <div className='searchIcon' style={{ alignItems: 'center' }}>
      <input
        className='field input'
        type='text'
        placeholder='Search Blog'
        style={{
          width: '80%',
          // height: '100%',
          paddingLeft: '50px',
          borderRadius: '3vmin',
        }}
        onChange={ev => {
          const result = fuse.search(ev.target.value);
          const resultList = result.map(res => res.item);
          setShownBlogs(
            resultList.length > 0
              ? resultList
              : ev.target.value.length > 0
                ? null
                : categoryToBlogs[blogSearch.tabs[chosenTab]],
          );
        }}
      />
    </div>
  );

  function BlogsGrid(num: Number) {
    const template = 'repeat(' + num + ', minmax(0, 1fr))';
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: template,
          columnGap: '2%',
        }}
      >
        {shownBlogs!.map((blog, id) => {
          return (
            <div>
              <BlogTile
                blog={blog}
                url={`/blog/${blog.id}`}
                showPreview={true}
                shaded={false}
              />
            </div>
          );
        })}
      </div>
    );
  }
  const NoBlogs = (
    <div className='fs-x-large centered' style={{ width: '100%' }}>
      No Blog Found
    </div>
  );

  return isDesktop() ? (
    <div>
      <div className='row' style={{ marginBottom: '50px' }}>
        <div style={{ width: '70%', marginTop: '10px' }}>{TabsBar}</div>
        <div style={{ width: '30%' }}>{SearchBox}</div>
      </div>
      <div>{shownBlogs != null ? BlogsGrid(3) : NoBlogs}</div>
    </div>
  ) : (
    <div>
      <div style={{ width: '100', marginBottom: '20px' }}>{SearchBox}</div>
      <div className='fs-smaller'>{TabsBar}</div>
      <div>
        {shownBlogs != null ? (
          BlogsGrid(1)
        ) : (
          <div style={{ marginTop: '30px' }}> {NoBlogs} </div>
        )}
      </div>
    </div>
  );
}
