import { BlogTile } from '../components/blogTile/blogTile';
import { ComingSoon } from '../components/misc';
import { blogData } from '../data/blog';
import { isDesktop } from '../utils';
import { BlogSearch } from '../components/blogSearch/blogSearch';

export function Blog(): JSX.Element {
  return isDesktop() ? (
    <div style={{ paddingLeft: '11vw', paddingRight: '11vw' }}>
      <div
        className='col'
        style={{ width: '80vw', marginInline: 'auto', alignItems: 'center' }}
      >
        <p className='title2 fs-x-large'>{blogData.title}</p>
        <p
          className='shady-70 centered'
          style={{ width: '90%', marginTop: '2.4vw' }}
        >
          {blogData.description}
        </p>
      </div>

      <div className='row' style={{ gap: '1%', marginBottom: '50px' }}>
        <div className='col' style={{ width: '65%', height: '100%' }}>
          <div>
            <BlogTile
              blog={blogData.blogs.blogs[0]}
              url={'/blog/0'}
              showPreview={true}
              shaded={true}
            />
          </div>
        </div>

        <div
          className='col'
          style={{
            width: '35%',
            rowGap: '2vw',
          }}
        >
          <div style={{ flexBasis: '0px', flexGrow: '1', overflow: 'hidden' }}>
            <div style={{ height: '50%' }}>
              <BlogTile
                blog={blogData.blogs.blogs[1]}
                url={'/blog/1'}
                showPreview={false}
              />
            </div>
            <div style={{ height: '50%' }}>
              <BlogTile
                blog={blogData.blogs.blogs[2]}
                url={'/blog/2'}
                showPreview={false}
              />
            </div>
          </div>
        </div>
      </div>

      <BlogSearch blogSearch={blogData.blogs} />
    </div>
  ) : (
    <div style={{}}>
      <div
        className='col'
        style={{ marginInline: 'auto', alignItems: 'center' }}
      >
        <p className='title2 fs-x-large'>{blogData.title}</p>
        <p className='shady-70 centered' style={{ marginTop: '2.4vw' }}>
          {blogData.description}
        </p>
      </div>
      <BlogTile
        blog={blogData.blogs.blogs[0]}
        url={blogData.blogs.blogs[0].url}
        showPreview={true}
        shaded={true}
      />

      <BlogSearch blogSearch={blogData.blogs} />
    </div>
  );
}
